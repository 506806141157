<template>
  <div class="d-flex-column align-center main rel-wrap">
    <!-- only have header-bg if no guy   -->
    <el-form label-position="top" class="main full-width input-underline" :key="componentKey">
      <el-card>
        <div class="mw-400">
          <div class="main">
            <div>
              <h4>Registration Details</h4>
              <LogoTitleSubtitle
                v-if="current.entity && current.price"
                :title="current.entity.name"
                :subtitle="
                  `Team Registration: ${formatMoney(current.price.pricing.competition.price)}`
                "
                :img="imgEndpoint(current.entity.association._id)"
              />
            </div>
          </div>
        </div>
      </el-card>

      <el-card>
        <div class="mw-400">
          <h4>Team Details</h4>
          <div class="main">
            <SuperInput
              :field="form.name"
              v-model="form.name.value"
              :errors.sync="form.name.errors"
            />
            <SuperInput
              :field="form.contactName"
              v-model="form.contactName.value"
              :errors.sync="form.contactName.errors"
            />
        
                    <vue-tel-input
                        v-model="form.contactNumber.value"
                        :dropdownOptions="props"
                        :preferredCountries="preferredCountries"
                        :autoFormat="true"
                        :validCharactersOnly="true"
                        mode="international"
                        @input="handlePaste"
                        @validate="validateMobile"
                        :class="!isValidInternationalMobile ? 'red' : ''" 
                      ></vue-tel-input>
                      <span v-if="!isValidInternationalMobile" class="red">
                        Invalid Mobile Number
                      </span>
            <SuperInput
              :field="form.contactEmail"
              v-model="form.contactEmail.value"
              :errors.sync="form.contactEmail.errors"
            />
            <div class="current-address mt-2">
              Current Address:
              <br />
              <span v-for="(line, index) in currentAddress" :key="index">
                {{ line !== ", " ? line : "" }}
              </span>
              <br />
              <div>
                <button
                  class="button-invisible button-address"
                  :class="addressError ? 'red' : ''"
                  @click.prevent="toggleManualAddress"
                >
                  {{ manualAddress ? "Search for Address" : "Enter Address Manually" }}
                </button>
              </div>
            </div>
            <AddressAutocomplete
              v-if="!manualAddress"
              id="address-autocomplete"
              classname="autocomplete"
              :enableGeolocation="true"
              placeholder="Search for address"
              @placechanged="getAddressData"
            />
            <div v-if="manualAddress">
              <SuperInput
                :field="form.addressLine1"
                v-model="form.addressLine1.value"
                :errors.sync="form.addressLine1.errors"
              />
              <SuperInput
                :field="form.addressLine2"
                v-model="form.addressLine2.value"
                :errors.sync="form.addressLine2.errors"
              />
              <SuperInput
                :field="form.suburb"
                v-model="form.suburb.value"
                :errors.sync="form.suburb.errors"
              />
              <SuperInput
                :field="form.state"
                v-model="form.state.value"
                :errors.sync="form.state.errors"
              />
              <SuperInput
                :field="form.postcode"
                v-model="form.postcode.value"
                :errors.sync="form.postcode.errors"
              />
              <SuperInput
                :field="form.country"
                v-model="form.country.value"
                :errors.sync="form.country.errors"
              />
            </div>
            <SuperInput
              :field="form.contact2Name"
              v-model="form.contact2Name.value"
              :errors.sync="form.contact2Name.errors"
            />
            <vue-tel-input
                        v-model="form.contact2Number.value"
                        :dropdownOptions="props"
                        :preferredCountries="preferredCountries"
                        :autoFormat="true"
                        :validCharactersOnly="true"
                        mode="international"
                        @input="handlePasteOptional"
                        @validate="validateOptionalMobile"
                        :class="!isValidOptionalMobile ? 'red' : ''" 
                      ></vue-tel-input>
                      <span v-if="!isValidOptionalMobile" class="red">
                        Invalid Mobile Number
                      </span>
            <SuperInput
              :field="form.contact2Email"
              v-model="form.contact2Email.value"
              :errors.sync="form.contact2Email.errors"
            />
            <SuperInput
              :field="form.uniformShirtColor"
              v-model="form.uniformShirtColor.value"
              :errors.sync="form.uniformShirtColor.errors"
            />
            <SuperInput
              :field="form.notes"
              v-model="form.notes.value"
              :errors.sync="form.notes.errors"
            />
          </div>
        </div>
      </el-card>

      <el-card v-if="termsRequired" class="mt-1">
        <div class="mw-400">
          <CheckBoxLine v-model="termsAgreed" ref="termsAgreed">
            <p class="f-14">
              Any terms sent through from competition.
              <a href="https://www.nrl.com/privacy-policy" target="_blank" @click.stop
                >NRLs Privacy Policy</a
              >.
            </p>
          </CheckBoxLine>
        </div>
      </el-card>

      <div class="d-flex-column main mw-400 mb-2">
        <div>
          <el-button type="primary" class="full-width" @click="submitForm">
            Next
          </el-button>
        </div>
        <div>
          <el-button @click="handleCancel" class="full-width button-invisible" type="success"
            >Cancel
          </el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import fp from "lodash/fp";
import CheckBoxLine from "@/components/form/fields/CheckBoxLine.vue";
import SuperInput from "@/components/form/fields/SuperInput.vue";
import AddressAutocomplete from "@/components/form/fields/AddressAutocomplete.vue";
import LogoTitleSubtitle from "@/components/LogoTitleSubtitle.vue";
import { errorOrPass, flatten, makeForm } from "@/utils/forms";
import { isBelowAge } from "@/utils/date/minor";
import { formatMoney } from "@/utils/money";
import { countries } from "@/utils/constants";
import msg from "@/utils/constants/msg";
import { endpointParams } from "@/utils/constants/api";
import {
  choiceStrategy,
  emailStrategy,
  mobileStrategy,
  emailStrategyOptional,
  mobileStrategyOptional
} from "@/utils/validators/strategy";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default {
  name: "TeamRegistrationForm",
  components: {
    SuperInput,
    CheckBoxLine,
    LogoTitleSubtitle,
    AddressAutocomplete
  },
  mounted() {
    // TODO pull through any existing team data if also going to be used to edit team

    // Get pricing
    this.$store.commit("root/LOADING", true);
    this.$http
      .post("/nrl/api/v1/portal/registration-team/pricing", {
        entityId: this.current.entity._id,
        entityType: "competition"
      })
      .then(response => {
        if (response.data && response.data.data) {
          this.updateCurrent({
            price: response.data.data[0]
          });
          this.$store.commit("root/LOADING", false);
        } else {
          this.$store.commit("root/LOADING", false);
          window.scrollTo(0, 0);
          this.PUSH_NOTIFICATION({
            msg: msg.error.apiError,
            type: "warning"
          });
        }
      })
      .catch(error => {
        this.$store.commit("root/LOADING", false);
        window.scrollTo(0, 0);
        this.PUSH_NOTIFICATION({
          msg:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : msg.error.apiError,
          type: "warning"
        });
      });
  },
  computed: {
    ...mapGetters("registrationTeams", ["current"]),
    ...mapGetters("user", ["userProfile"]),
    currentAddress() {
      return [
        this.form.addressLine1.value,
        this.form.addressLine2.value,
        this.form.suburb.value,
        this.form.state.value,
        this.form.postcode.value
      ]
        .map(s => `${s}, `)
        .concat([this.form.country.value]);
    },
    addressError() {
      const concatE = fp.flow(
        fp.concat(this.form.addressLine1.errors),
        fp.concat(this.form.addressLine2.errors),
        fp.concat(this.form.suburb.errors),
        fp.concat(this.form.state.errors),
        fp.concat(this.form.postcode.errors)
      )([]);
      return concatE.length !== 0;
    }
  },
  methods: {
    ...mapActions("views", ["triggerEvent"]),
    ...mapActions("registrationTeams", ["updateCurrent"]),
    ...mapMutations("views", ["PUSH_NOTIFICATION"]),
    ...mapMutations("root", ["LOADING"]),
    handleCancel() {
      this.$router.push({ name: "compsearch" });
    },
    submitForm() {
      // Trigger the validation for all fields
      this.triggerEvent();

      // Validate the form with a single function
      if (!errorOrPass(this.form, null, this, window)) return;
      const details = flatten(this.form);
      // Form Profile Data
      const apiDetails = this.termsRequired
        ? {
            ...details,
            termsAgreed: this.termsAgreed
          }
        : details;
      if(this.$route?.params?.memberType) apiDetails.memberType = this.$route?.params?.memberType;
      apiDetails.countryCode = this.countryCode;
      apiDetails.countryCallingCode = this.countryCallingCode;
      this.$store.commit("root/LOADING", true);
      this.$http
        .post("/nrl/api/v1/portal/registration-team/check-duplicate-name", {
          competitionId: this.current.entity._id,
          name: apiDetails.name
        })
        .then(() => {
          this.$store.commit("root/LOADING", false);

          // Update current rego
          this.updateCurrent({
            teamDetails: apiDetails
          });

          if (
            this.current.price.pricing.competition.additionalQuestions &&
            this.current.price.pricing.competition.additionalQuestions.length > 0
          ) {
            this.$router.push({ name: "team-additional-questions" });
          } else if (
            this.current.price.pricing.competition.saleableItems &&
            this.current.price.pricing.competition.saleableItems.length > 0
          ) {
            this.$router.push({ name: "teamaddon" });
          } else {
            this.$router.push({ name: "teampayment" });
          }
        })
        .catch(error => {
          this.$store.commit("root/LOADING", false);
          window.scrollTo(0, 0);
          this.PUSH_NOTIFICATION({
            msg:
              error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : msg.error.apiError,
            type: "warning"
          });
        });
    },
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData(addressData) {
      this.form.addressLine1.value =
        (addressData.street_number ? `${addressData.street_number} ` : "") + addressData.route;
      this.form.addressLine2.value = "";
      this.form.suburb.value = addressData.locality;
      this.form.state.value = addressData.administrative_area_level_1;
      this.form.postcode.value = addressData.postal_code;
      this.form.country.value = addressData.country;
      this.componentKey += 1;
    },
    toggleManualAddress() {
      this.manualAddress = !this.manualAddress;
      this.componentKey += 1;
    },
    formatMoney,
    imgEndpoint(id) {
      return endpointParams.logo(id);
    },
    isBelowAge,
    validateMobile(data) {
      if(!data | !data.number | !data.countryCode) {
        return;
      }
      const parsedNumber = parsePhoneNumberFromString(data.number, data.countryCode)
      if (parsedNumber.isValid()) {
        this.isValidInternationalMobile = true;
        const formattedNumber = parsedNumber.formatInternational();

        // Update the model with the formatted number;
        this.form.contactNumber.value = formattedNumber;
        this.countryCode = data.countryCode;
        this.countryCallingCode = data.countryCallingCode;
      } else {
        this.isValidInternationalMobile = false;
      }
    },
    handlePaste(number, phoneObject) {
      if(phoneObject.formatted) {
        this.form.contactNumber.value  = phoneObject.formatted;
      }
      // Trigger the validation method
      this.validateMobile(phoneObject);
    },
    validateOptionalMobile(data) {
      if(!data | !data.number | !data.countryCode) {
        return;
      }
      const parsedNumber = parsePhoneNumberFromString(data.number, data.countryCode)
      if (parsedNumber.isValid()) {
        this.isValidOptionalMobile = true;
        const formattedNumber = parsedNumber.formatInternational();

        // Update the model with the formatted number;
        this.form.contact2Number.value = formattedNumber;
        
      } else {
        this.isValidOptionalMobile = false;
      }
    },
    handlePasteOptional(number, phoneObject) {
      if(phoneObject.formatted) {
        this.form.contact2Number.value = phoneObject.formatted;
      }
      // Trigger the validation method
      this.validateOptionalMobile(phoneObject);
    },
  },
  data() {
    return {
      form: makeForm({
        name: { label: "Team Name", required: true },
        contactName: { label: "Contact Full Name", required: true },
        contactNumber: { label: "Contact Mobile" },
        contactEmail: { label: "Contact Email", validators: [emailStrategy] },
        addressLine1: { label: "Address Line 1" },
        addressLine2: { label: "Address Line 2 (optional)", required: false },
        suburb: { label: "Suburb" },
        state: { label: "State" },
        postcode: { label: "Postcode" },
        country: {
          component: "TypedSelect",
          validators: [choiceStrategy(countries)],
          label: "Country / Area",
          options: countries
        },
        contact2Name: { label: "Contact 2 Full Name (optional)", required: false },
        contact2Number: {
          label: "Contact 2 Mobile (optional)",
          required: false
        },
        contact2Email: { label: "Contact 2 Email (optional)", validators: [emailStrategyOptional] },
        uniformShirtColor: { label: "Uniform Shirt Colour (optional)", required: false },
        notes: { label: "Notes (optional)", required: false }
      }),
      props: {
        showSearchBox: true,
        showFlags: true,
        showDialCodeInList: true,
        showDialCodeInSelection: true,
      },
      countryCode:null,
      countryCallingCode:null,
      preferredCountries: ["AU", "NZ", "WS", "TO", "PG", "CK", "US", "FJ", "GB", "FR"],
      isValidInternationalMobile: true,
      isValidOptionalMobile: true,
      termsRequired: false,
      termsAgreed: false,
      componentKey: 1,
      manualAddress: true
    };
  }
};
</script>

<style lang="scss" scoped>
.button-address {
  cursor: pointer;
  color: $secondary;
  font-size: 0.85rem;

  &:hover,
  &:focus {
    color: $rl-highlight !important;
  }

  &.red {
    color: red;
  }
}

.left {
  padding-left: 0;
}

h4 {
  font-family: $fontBold;
}

.rel-wrap {
  position: relative;
}

.warning {
  color: red;
}

.alert {
  z-index: 5;
}

.data-table {
  margin-bottom: 2rem;
}

.upload-modal {
  padding: 1rem;
}

.header-bg {
  background-image: url("/bg/my_account_header.png");
  background-size: 100% 100%;
  height: 400px;
  width: calc(100% + 30px);
  position: absolute;
  top: -40px;
  z-index: 0;
}

.profile {
  z-index: 9;
}

.current-address {
  & + div {
    margin-top: 5px;
  }

  div {
    width: 100%;
    text-align: right;
  }

  span {
    font-size: 0.875rem;
  }
}

.f-14 {
  font-size: 0.875rem;
}
.red{
    color:red
  }
</style>
